.main_wrapper .enviornment_consideration li button {
    border: none !important;
    color: #000;
    width: 100%;
    padding: 20px;
    background: #F5F7FF;
    border-radius: 8px 8px 0px 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #6A6F89;
}
.enviornment_consideration{
    border-bottom: none !important;
}
.color_div .enviornment_consideration li .nav-link.active {
    border: none !important;
    color: #fff !important;
    border-bottom: 1px solid #233076 !important;
    margin: 0 auto;
    background: linear-gradient(88.35deg, #233076 1.4%, #19619C 98.48%);
    box-shadow: 0px 4px 10px rgb(25 97 156 / 20%);
    border-radius: 0px 0px 0px 0px;
    padding: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.04em;
}
