.download_report .download_css {
  border: 1px solid #233076;
  background: #fff;
  opacity: 0.4;
  font-weight: 700;
  height: 58px;
  border-radius: 8px;
}

.global_link.download_report{
  margin-left: 0;
}

.setup-content .sve_next .downloadcss {
  border-radius: 3px !important;
  color: #05b49d !important;
  border: 2px solid #05b49d !important;
}
.w-90 {
  /* width: 200px; */
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 20px;
}
.w-20 {
  width: 100px;
}
.manage-detail button {
  text-decoration: none;
  letter-spacing: 3px;
  /* display: flex; */
  white-space: nowrap;
}
