.login_part {
  width: 100%;
  min-height: 968px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .signup_middle{
  min-height: 968px;
  align-items: flex-start;
}
.sing_log {
  width: 40%;
  background: #ffffff;
  border-radius: 16px;
  padding: 64px 65px;
}
.login-btn{
  letter-spacing: 1px !important;
}
.twoFA {
  width: 40%;
  padding: 0px;
}

.page_save {
  width: 200px;
  height: 58px;
  left: 350px;
  top: 835px;
  background: linear-gradient( 
94.47deg, #233076 1.14%, #19619C 114.41%);
  box-shadow: 0px 8px 52px rgb(64 84 164 / 36%);
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-right: 30px;
}
.page_width {
/*   width: auto !important; */
  padding: 20px !important;
  text-decoration: none;
}

.FAverify {
  display: flex;
    justify-content: center;
    align-items: center;
}

.sing_log1 {
  width: 100%;
  background: #ffffff;
  padding: 40px 60px;
}

.svg{
  height: 10%;
}

.sing_one1 img {
  width: 25%;
}

.sing_one1 {
  text-align: center;
}

.text_sing2 {
  text-align: justify;
}

.textPara{
  font-size: 15px;
}

.textHead{
  font-weight: bold;
}

.Privacy {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: #2b377b;
  margin-bottom: 20px;
}

.sing_one img {
  width: 65%;
}
.sing_one {
  text-align: center;
}
.text_sing {
  text-align: center;
}
.Account {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 44px;
  text-align: center;
  color: #2b377b;
  margin-bottom: 6px;
}
.faster_oval {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #6b6b6b;
  margin-bottom: 54px;
}
.name_nf {
  background: #f8fbff;
  border: 1px solid #f1f5fb;
  box-sizing: border-box;
  border-radius: 6px;
  height: 52px;
}
.name_nf {
  background: #f8fbff;
  border: 1px solid #f1f5fb;
  box-sizing: border-box;
  border-radius: 6px;
}
.make_form {
  margin-bottom: 24px;
}
.select_map {
  height: 52px;
  left: 421px;
  top: 559px;
  background: #f8fbff;
  /* border: 1px solid #2b377b !important; */
  box-sizing: border-box;
  border-radius: 6px;
  color: #333333;
  -webkit-appearance: menulist !important;
}
select#selectbasic {
  -webkit-appearance: menulist;
}
.form_link {
  width: 100%;
  text-align: center;
}
/* .view_bottoma a {
    justify-content: center;
    text-decoration: none;
    width: 100%;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    background: linear-gradient(88.35deg, #233076 1.4%, #19619C 98.48%);
    height: 60px;
    line-height: 26px;
    letter-spacing: 5px;
} */
.view_bottoma a {
  justify-content: center;
  text-decoration: none;
  width: 100%;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  background: #233076;
  height: 60px;
  line-height: 16px;
  max-width: 200px;
  height: 58px;
}
.view_bottoma a:hover {
  color: #fff !important;
}
.view_bottoma input {
  justify-content: center;
  text-decoration: none;
  width: 100%;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  background: #233076;
  height: 60px;
  line-height: 26px;
  max-width: 260px;
  margin-left: 20px;
}
.view_bottoma input:hover {
  color: #fff !important;
}
.hompop {
  display: flex;
  justify-content: left;
  align-items: center;
}

.deckle {
  width: 22px;
  height: 22px;
  border: 2px solid #dddddd;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 3px;
}
.deckle:checked {
  background-color: #05b49d;
  border-color: #05b49d;
}
.date_yup {
  padding-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
}
.plform {
  margin-bottom: 20px;
}
.glee {
  margin-top: 20px;
}
.view_bottoma button {
  justify-content: center;
  text-decoration: none;
  width: 50%;
  max-width: 200px;
  height: 58px;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  /* background: #233076; */
  background: linear-gradient(88.35deg, #233076 1.4%, #19619c 98.48%);
  line-height: 16px;
  letter-spacing: 5px;
  flex-direction: row;
  flex-wrap: wrap;
  float: left;
  margin: 0;
}
.view_bottoma button:hover {
  color: #fff !important;
}
.view_bottoma {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
}
.step-forms {
  display: none;
  transform-origin: top;
  animation: animate 1s;
}

.step-forms-active {
  display: block;
}
.refree i {
  font-size: 25px;
  padding: 0 10px;
}
.help-block {
  color: red;
}
/* a[disabled] {
  pointer-events: none;
  opacity: 65% !important;
} */
/*login*/

/*login*/
.eye-frame {
  width: 100%;
  position: relative;
}

.eye-under {
  position: absolute;
  content: '';
  right: 20px;
  top: 51px;
  color: #2b377b;
}

.form-check.hompop {
  margin-left: -20px;
}

span.page_width.page_save.d-block {
  width: 200px !important;
  height: 58px;
}
.disabledd{
  opacity: 0.33 !important;
}

.disabled-link{
  pointer-events: none;
}
