ul.grapic {
  display: block;
  margin-bottom: 0;
  padding: 0;
}
ul.grapic li {
  list-style: none;
  display: inline-block;
  margin: 10px 0;
}
.modal_box.modal.show .modal-dialog {
  transform: translateY(30px);
  width: 100%;
  max-width: 540px;
}
/* .modal_box .modal-header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  border-bottom: none;
} */
.modal-box .modal-body {
  padding: 2rem;
}

.min_wid {
  min-width: 50%;
  height: 50px;
}

.btn_fix_flex {
  display: flex;
  width:100%
}

.mar_righ_fix {
  margin-right: 5px;
}

.pad_null_fix {
  padding: 0px !important;
}

.modal_cont{
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  max-height: 90%;
}

.menber_j {
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 24px !important;
  display: flex !important;
  align-items: center !important;
  text-align: right !important;
  letter-spacing: 0.08em;
  text-transform: uppercase !important;
  color: #05b49d !important;
  text-decoration: none !important;
  margin-bottom: 0 !important;
}
button.edit {
  color: #6d79a7;
  font-weight: 700;
}
.text_tnp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  -webkit-gap: 15px;
  -o-gap: 15px;
  -moz-gap: 15px;
}
.helop_tex {
  flex: 0 0 80%;
}
.text_image img {
  width: 120px;
  height: 120px;
  border-radius: 100%;
}

.skill-save-btn {
  width: 195px;
}


option.selectOptions {
  color: #8d8c8c;
}

span.skills {
  background: #f5f7ff;
  border-radius: 100px;
  padding: 6px 16px;
  margin-left: 6px;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: .02em;
  color: #4f5367;
}

.inputHalfWidth{
  width: 100%;
}

.imageCenter {
  display: flex;
  justify-content: center;
}

.spinner {
  border: 3px solid rgba(88, 83, 83, 0.1);
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}