/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.gender {
  margin-top: -13px;
  display: flex;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #4f5367;
}
.male {
  margin: auto;
}
.female {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 1.5rem!important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #4f5367;
}