.card_anchor{
    text-decoration: none;
}
.input-field {
    width: 100%;
    max-width: 350px;
}
.input-field .form-control {
    border: none;
    background: #EBEFFF;
    height: 52px;
    border-radius: 42px;
}
.input-field span {
    background: #EBEFFF;
    border: none;
    border-radius: 52px;
    padding: 0 20px;
    color: #c9c9c9;
}
.input-field .form-control:focus {
    background-color: #EBEFFF;
    border-color: none;
    outline: 0;
    box-shadow: none;
}
.card_1 img {
    width: 100%;
    object-fit: cover;
    border-radius: 7px;
}
.card_1 p {
    color: #4F5367;
    margin-top: 15px;
    font-weight: 600;
}
.card_1 a{
    text-decoration: none;
}
.card_1 iframe.embed-responsive-item {
    width: 100%;
    height: 550px;
    object-fit: cover;
}
.form_start .form-group .select_t {
    height: 52px;
    margin-top: 10px;
    padding: 10px;
}

.articlesElements {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 50px;
    row-gap: 50px;
}

.articleCard {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

}

.articleCard:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

.cardElem{
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    height: 320px;
    margin: 1rem;
    position: relative;
    border-radius: 20px;
}

.articleCardContent {
    padding-left: 20px;
    padding-top: 10px;
}

#article_athor {
    font-size: 15px;
    font-family: inherit;
}

.articleTitle {
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.articleDrop {
    width: 120px;
}

.select_wrapper {
    display: flex ;
    width: 230px;
}
.sort-icon {
    align-items: center;
    display: flex;
    margin-left: -17px;
}

.articleFilters {
    display: flex;
    gap: 30px;
}

.no_article_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .articlesElements {
        grid-template-columns: 1fr 1fr;
    }
 }

 @media screen and (max-width: 1784px) {
    .articlesElements {
        grid-template-columns: 1fr 1fr;
    }
 }

 @media screen and (max-width: 1291px) {
    .articlesElements {
        grid-template-columns: 1fr;
    }
 }