.omne {
    width: 100%;
}
.impa {
    justify-content: center !important;
}

.re-submit1 {
    color: #05B49D !important;
    background: #f6f7fa;
    border: none;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 16px !important;
    letter-spacing: 2px;
}

.interlinked {
    line-height: 30px !important;
}

.lang_css_fix {
    display: inline !important;
}

.lang_css_fix2 {
    display: inline;
}